<template>
  <div>
    <el-form ref="from" inline>
      <el-row :gutter="2">
        <el-col :span="5" :offset="6">
          <el-form-item label="Customer Type" label-width="100px">
            <el-input readonly  v-model.trim="phoneType"  label-width="150px"/>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="0"  v-if="showUpdateCycle">
          <el-button type="primary" @click="updateCycle()">Customer adjustment cycle</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="2">
        <el-col :span="5" :offset="6">
          <el-form-item label="phone" label-width="100px">
            <el-input v-model.trim="phone" placeholder="Please enter the outgoing number" label-width="150px"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="2" >
        <el-col :span="5" :offset="6">
          <el-button type="primary" @click="registered()">register</el-button>
          <el-button type="primary" @click="unregistered()">logout</el-button>
        </el-col>
      </el-row>

      <el-row :gutter="2" style="margin-top: 3px">
        <el-col :span="5" :offset="6">
          <el-button type="primary" @click="dial()">Calling</el-button>
          <el-button type="danger" @click="hangup()">hang up</el-button>
        </el-col>
      </el-row>

      <el-row :gutter="2" style="margin-top: 3px">
        <el-col :span="5" :offset="6">
          <el-button type="success" @click="callin(true)">answer</el-button>
          <el-button type="danger" @click="callin(false)">refuse</el-button>
        </el-col>
      </el-row>

      <el-row :gutter="2" style="margin-top: 6px">
        <el-col :span="5" :offset="6">
          <el-button type="success" @click="checkAccountStatus()">Check account status</el-button>
        </el-col>
      </el-row>

      <el-row :gutter="2" style="margin-top: 10px"  v-if="showKefu">
        <el-col :span="5" :offset="6">
          <el-form-item label="remarks" label-width="150px">
            <el-input v-model.trim="remarks" placeholder="Please enter the remarks "></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="0">
          <el-button type="primary" @click="submitRemarks()">Submit remarks</el-button>
        </el-col>
      </el-row>

<!--      <el-row :gutter="2" style="margin-top: 10px"  v-if="showKefu">-->
<!--        <el-col :span="5" :offset="6">-->
<!--          <el-button type="primary" @click="sendMessage()">send message</el-button>-->
<!--        </el-col>-->
<!--      </el-row>-->

    </el-form>
    <!-- 表格组件 -->
    <el-table :data="listData" style="width: 100%;margin-top: 15px"  v-if="showCollection">
      <!-- 定义表头 -->
      <el-table-column prop="login_name" label="Mobile" width="180"></el-table-column>
      <el-table-column prop="user_name" label="User Name"></el-table-column>
      <el-table-column prop="name" label="Project"></el-table-column>
      <el-table-column prop="loan_amt" label="LoanAmt (PHP)"></el-table-column>
      <el-table-column prop="due_time" label="DueTime"></el-table-column>
      <el-table-column prop="fact_no_repay_amt" label="Over Amount(PHP)"></el-table-column>
      <el-table-column prop="yqts" label="Over Days"></el-table-column>
      <!-- 可以根据需要添加更多的列 -->
    </el-table>
    <!-- WebRTC组件 -->
    <RTC
      ref="rtc"
      :options="options"
      @getCallerNumber="getCallerNumber"
      @getError="getError"
    />
  </div>
</template>
<script>
import RTC from '@/components/WebRTC'
import websocket from '@/WebSocket'
import apiRequest from '../utils/api-request'

export default {
  components: { RTC },
  mixins: [websocket, apiRequest],
  data() {
    return {
      showUpdateCycle: false,
      phoneType: '',
      listData: [],
      showKefu: true,
      showCollection: false,
      remarks: '',
      status: '未注册',
      statusClass: 'unregistered',
      phone: '',
      callIN: '',
      BUSY: -1,
      busyList: {},
      destination: {},
      dialogVisible: false,
      transferDst: '',
      dialogVisible2: false,
      conferenceDst: '',
      WebConf: {
        webUrl: 'wss://mxt.zadacash.com:1238', // centrifuge的websocket地址
        secret: 'wV6bQBPFngWjBTxZtmLAX27QxmMYv30T', // jwt鉴权秘钥
        extension: '6001' // 分机
      },
      options: {
        ws: 'wss://mxt.zadacash.com:8089/ws', // websocket地址
        sipServer: 'mxt.zadacash.com', // sip注册地址
        stunServer: 'stun:mxt.zadacash.com:3478', // stun服务地址
        turnServer: 'turn:mxt.zadacash.com:3478', // turn服务地址
        turnName: 'mixcom2024', // turn用户名
        turnPass: 'Moi9eE34Xdcc', // turn密码
        extension: '6001', // 分机号
        password: 'RSOkFE0Q_6001_X11', // 分机密码
        ringin: '/ringin.wav', // 振铃声音的文件地址
        ringout: '/ringout.wav', // 振铃声音的文件地址
        hangup: '/dududu.wav', // 挂断铃声的文件地址
        enable: false, // 自动注册
        debug: false // 信令调试
      },
      apiConfig: {
        BaseUrl: 'https://mxt.zadacash.com:8090',
        ApiSercet: 'upJtkYys6SttVLHmA7FiAHG4QDptT510'
      }
    }
  },
  created() {
    this.setWebsocket(this.WebConf) // 向centrifuge中设置参数
    this.apiGetDnd({ extension: this.options.extension }).then((response) => {
      this.busyList = response.data.dnd_typelist
      this.BUSY = response.data.status
    })
    this.apiGetDestination().then((response) => {
      this.destination = response.data
    })
  },

  mounted() {
    const token = localStorage.getItem('userToken')
    if (token === '') {
      this.$router.push({ name: 'login' })
    }
    const params = {
      token: token
    }
    this.apiValidateToken(params).then((response) => {
      if (response.success) {
        const userType = localStorage.getItem('userType')
        if (userType === '2') {
          this.$router.push({ name: 'remind' })
        } else if (userType === '3') {
          this.$router.push({ name: 'telephoneNotHandle' })
        } else {
          const mikangyunAccount = localStorage.getItem('mikangyunAccount')
          const mikangyunPassword = localStorage.getItem('mikangyunPassword')
          this.options.extension = mikangyunAccount
          this.options.password = mikangyunPassword
        }
      } else {
        this.$router.push({ name: 'login' })
      }
    })
    // 这里订阅 分机状态
    this.extensionStatus((data) => {
      switch (data.Status) {
        case '0':
          this.status = '空闲'
          this.statusClass = 'registered'
          break
        case '1':
          this.status = '通话中'
          this.statusClass = 'calling'
          break
        case '2':
          this.status = '忙线'
          this.statusClass = 'hangup'
          break
        case '4':
          this.status = '未注册'
          this.statusClass = 'unregistered'
          break
        case '8':
          this.status = '振铃中'
          this.statusClass = 'ring'
          break
        case '16':
          this.status = '保持中'
          this.statusClass = 'calling'
          break
        case '-1':
          this.status = '不存在'
          this.statusClass = 'unregistered'
          break
      }
    })

    // 这里订阅弹屏
    var popup_params = {
      extension: this.options.extension, // 要订阅哪个分机的弹屏。必填
      popinout: 'ALL', // 弹屏类型，呼入(dialin)、呼出(dialout)或全部(all)弹屏。必填
      poptime: 'RING', // 弹屏时机，振铃(RING)或接通(LINK)。必填
      poptype: '2', // 弹屏方式，1为弹出新窗口，2为指定js回调。必填
      popurl: '', // 弹屏地址，只有当poptype为1时才有效
      cover_win: true, // 是否覆盖弹屏窗口，true为覆盖，false为不覆盖。只有当poptype为1时才有效
      callback: (data) => {
        // 回调函数
        console.log(data, 'received data')
      }, // 回调方法名，只有当poptype为2时才有效。选填
      trim_prefix: '', // 号码前缀。选填
      phone_number_length: '' // 限制对方电话号码的长度。选填
    }
    this.extensionPopup(popup_params)
  },
  methods: {
    // 回调方法获取来电号码
    getCallerNumber(phone) {
      this.callIN = phone
      console.log(phone)
      this.listData = []
      // 判断用户是否是催收 去拉取数据
      // 营销的客户 接通后发送短信
      if (this.showCollection) {
        const params = {
          phone: this.callIN,
          extension: this.options.extension
        }
        this.apiCollectionInfo(params).then((response) => {
          this.listData = response.msg
        })
      } else {
        const params = {
          phone: this.callIN,
          extension: this.options.extension
        }
        this.apiSendMessage(params).then((response) => {
          console.log(response.success)
        })
        this.apiGetPhoneType(params).then((response) => {
          this.phoneType = ''
          if (response.msg.length > 0) {
            const firstElement = response.msg[0]
            this.phoneType = firstElement.type
            if (firstElement.type === 1) {
              this.phoneType = 'New Customer'
              this.showUpdateCycle = false
            }
            if (firstElement.type === 2) {
              this.phoneType = 'Old Customer'
              this.showUpdateCycle = true
            }
          }
        })
      }
    },
    // eslint-disable-next-line no-unused-vars
    getError(type, error) {
      if (type === 'out') {
        console.log('呼出失败', error)
      } else if (type === 'in') {
        console.log('呼入失败', error)
      }
    },
    // 注册分机
    registered() {
      this.$refs.rtc.register()
    },
    // 注销分机
    unregistered() {
      this.$refs.rtc.unregister()
    },
    // 呼叫
    dial() {
      this.$refs.rtc.dial(this.phone)
    },
    // 呼入的接听与拒绝
    callin(flag) {
      this.$refs.rtc.callin(flag)
    },
    // 挂断
    hangup() {
      this.$refs.rtc.hangup()
    },
    // 示忙|示闲
    setDnd(dndcode) {
      const params = {
        extension: this.options.extension,
        dndcode: dndcode
      }
      this.apiSetDnd(params).then((response) => {
        if (response.success) {
          this.BUSY = dndcode
          this.$message({
            message: response.msg,
            type: 'success',
            offset: 45,
            center: true
          })
        } else {
          this.$message({
            message: response.msg,
            type: 'error',
            offset: 45,
            center: true
          })
        }
      })
    },
    // 转接
    transfer() {
      const params = {
        extension: this.options.extension,
        transdst: this.transferDst
      }
      this.apiTransfer(params).then((response) => {
        if (response.success) {
          this.$message({
            message: response.msg,
            type: 'success',
            offset: 45,
            center: true
          })
          this.dialogVisible = false
          this.transferDst = ''
        } else {
          this.$message({
            message: response.msg,
            type: 'error',
            offset: 45,
            center: true
          })
        }
      })
    },
    // 三方通话
    conference() {
      const params = {
        extension: this.options.extension,
        extensionDst: this.conferenceDst
      }
      this.apiConference(params).then((response) => {
        if (response.response) {
          this.$message({
            message: response.msg,
            type: 'success',
            offset: 45,
            center: true
          })
          this.dialogVisible = false
          this.transferDst = ''
        } else {
          this.$message({
            message: response.msg,
            type: 'error',
            offset: 45,
            center: true
          })
        }
      })
    },
    // 提交备注
    submitRemarks() {
      const params = {
        remarks: this.remarks,
        phone: this.callIN,
        extension: this.options.extension
      }
      console.log(params)
      this.apiSubmitRemarks(params).then((response) => {
        console.log(response.success)
        this.$notify({
          title: 'remarks',
          message: 'submit success！',
          type: 'success',
          duration: 2000 // 持续时间，单位毫秒，默认为4500
        })
        this.remarks = ''
      })
    },
    // 提交备注
    sendMessage() {
      const params = {
        phone: this.callIN,
        extension: this.options.extension
      }
      console.log(params)
      this.apiSendMessage(params).then((response) => {
        console.log(response.success)
        this.$notify({
          title: 'message',
          message: 'send success！',
          type: 'success',
          duration: 2000 // 持续时间，单位毫秒，默认为4500
        })
      })
    },
    // 提交备注
    checkAccountStatus() {
      const params = {
        extension: this.options.extension
      }
      this.apiCheckAccountStatus(params).then((response) => {
        let checkMessage = 'registered'
        let duration = 2000
        if (!response.msg) {
          checkMessage = 'Click to register again!'
          duration = 6000
          this.unregistered()
        }
        this.$notify({
          title: 'message',
          message: checkMessage,
          type: 'success',
          duration: duration // 持续时间，单位毫秒，默认为4500
        })
      })
    },
    updateCycle() {
      const params = {
        phone: this.callIN,
        extension: this.options.extension
      }
      this.apiUpdateCycle(params).then((response) => {
        this.$notify({
          title: 'message',
          message: 'Success',
          type: 'success',
          duration: 2000 // 持续时间，单位毫秒，默认为4500
        })
      })
    }
  }
}
</script>
<style scoped>
.registered {
  color: #67c23a;
}

.unregistered {
  color: #909399;
}

.calling {
  color: #f56c6c;
}

.hangup {
  color: #ff0000;
}

.ring {
  color: #e6a23c;
}
</style>
